import { Customer } from '@lambda/apis/src/customer/types';
import { Logger } from '@lambda/commons/logging/logger';
import axios from 'axios';
import cookie from 'js-cookie';

const logger = new Logger('customer-api');

const COOKIE_TOKEN = 'auth-token';
const COOKIE_USER = 'auth-user';

export const getCustomerFromCookie = async (): Promise<
  Customer | undefined
> => {
  const customer = cookie.get(COOKIE_USER);

  if (customer) {
    try {
      return JSON.parse(customer);
    } catch (e: any) {
      logger.error({ e, customer }, 'json parse error from customer cookie');
    }
  }

  const token = cookie.get(COOKIE_TOKEN);

  if (token) return axios.get('/api/customer/me');

  return undefined;
};
